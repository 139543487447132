<template>
  <review-card :key="`list-group-ter-co-${expanded}`" title="Tipos de Fluxo">
    <v-list-group
      v-for="(item, index) in flowTypes"
      :key="index"
      class="item-par"
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item-title>
          {{ item.flowType.name }}
        </v-list-item-title>
      </template>
      <div class="grey--text text--darken-3 body-2 pa-3">
        <div v-if="item.pointsOrder && item.pointsOrder.length > 0">
          <div class="font-weight-bold">Pares:</div>
          <view-tracking-impaction-point
            v-for="po in item.pointsOrder"
            :key="po.id"
            :tracking-point="po.trackingPoint"
            :impaction-point="po.impactionPoint"
            class="grey my-1"
          />
          <property-value property="Ponto afetado" :value="item.affectedSpot" />
        </div>
        <div class="d-flex flex-column">
          <property-value property="Instruções" :value="item.instructions" />
          <property-value
            property="Comentários aos terapeutas"
            :value="item.therapistComments"
          />
          <property-value
            property="Comentários ao paciente"
            :value="item.patientComments"
          />
        </div>
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "TherapyReviewFlowTypes",
  props: {
    flowTypes: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
